@mixin keybase {
  background-color: #333;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.keyboardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 570px;
  margin: 0 auto;
  gap: 5px;
}
.key {
  @include keybase;
  width: 50px;
  height: 70px;
}
.emptyKey {
  width: 25px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enterKey {
  @include keybase;
  width: 80px;
  height: 70px;
}

.deleteKey {
  @include keybase;
  width: 80px;
  height: 70px;
}

.correct {
  background-color: #4caf50;
  border: #4caf50 solid 2px;
  color: black;
}

.present {
  background-color: #e5f321;
  border: #e5f321 solid 2px;
  color: black;
}

.absent {
  background-color: #aaa;
  border: #aaa solid 2px;
  color: black;
}
