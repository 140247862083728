@import "./variables.scss";

@mixin box {
    font-size: 2em;
    font-weight: bold;
    width: $size;
    height: $size;
    border: solid 2px #555;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.correct {
    @include box;
    background-color: #4caf50;
    border: #4caf50 solid 2px;
    color: black;
}

.present{
    @include box;
    background-color: #f8ff92;
    border: #f8ff92 solid 2px;
    color: black;
}

.absent{
    @include box;
    background-color: #aaa;
    border: #aaa solid 2px;
    color: black;
}

.empty {
    @include box;
}

.edit {
    @include box;
}